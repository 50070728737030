@import "/src/mediaQueries.scss";

.FwNavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: var(--col-content-bg);
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    z-index: 50;
    transition: transform .3s ease-in-out, height .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), background-color .3s ease-out;

    @include gt-sm {
        &.FwNavbar--hidden {

            &:not(:hover) {
                transform: translateY(-50px);
                background-color: var(--col-lowAlpha);
                backdrop-filter: blur(10px);

                .FwNavbar__logo {
                    opacity: 0;
                }
    
                .FwNavbar__links {
                    opacity: 0;
                }
    
                .FwNavbar__colorSwitcher {
                    opacity: 0;
                }
            }
        }
    }

    @include lt-md {
        align-items: flex-start;
        padding-top: 10px;
        box-shadow: 0px 0px 36px -21px rgba(0,0,0,0.75);
        // background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6978992280505952) 89%, rgba(255,255,255,.5) 100%); 
        

        &.active {
            height: 230px !important;
            
        }
    }

}


.FwNavbar__logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    left:15px;
    border-radius: 50%;
    transition: border-radius .2s ease-in-out, background-color .3s ease-in-out, transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity .3s ease-in;
    background-color: transparent;
    opacity: 1;

    img {
        width: 50px;
    }

    &:hover {
        background-color: var(--col-bg);
        border-radius: 25%;
    }

    &:active {
        transform: rotate(45deg);
    }
}

.FwNavbar__links {
    opacity: 1;
    transition: opacity .3s ease-in;

    @include lt-md {
        position: absolute;
        bottom: 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: stretch;
    }

    & > li {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        float: left;

        @include lt-md {
            display: none;

            .FwNavbar.active & {
                display: block;
            }
        }
    }
}


.FwNavbar__burgerham {
    display: none;
    position: fixed;
    right: 25px;
    top: auto;
    width: 40px;
    height: 40px;
    padding: 10px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 50%;
    background-color: transparent;

    &::before, &::after {
        display: block;
        content: "";
        width: 100%;
        height: 3px;
        background-color: var(--col-secondary);
        transition: transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275), width .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .FwNavbar.active & {
        &::before {
            transform: rotate(45deg) translateX(10.5px);
        }

        &::after {
            transform: rotate(-45deg) translateX(10.5px);
        }
    }

    &:hover {
        &::before, &::after {
            width: 50px;
        }
    }

    @include lt-md {
        display: flex;
    }
}

.FwNavbar__colorSwitcher {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 15px;
    border-radius: 50%;
    background-color: var(--col-content-bg);
    opacity: 1;
    transition: filter .3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                opacity .3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                transform .4s cubic-bezier(0.175, 0.885, 0.32, 1.275),
                opacity .3s ease-in;

    svg {
        fill: var(--col-text);
        width: 50%;
        height: 50%;
        overflow: visible;
    }

    &:hover {
        filter: invert(20%);
    }

    &:active {
        transition: filter .3s;
        filter: invert(15%);
        transform: rotate(80deg);
    }   

    @include lt-md {
        opacity: 0;
        top: 15px;
        right: 80px;

        .FwNavbar.active & {
            opacity: 1;
        }
    }
}

.FwNavbar__status {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 110px;
    top: 15px;
    border-radius: 50%;
    background-color: var(--col-content-bg);

    svg {
        fill: var(--col-text);
        width: 50%;
        height: 50%;
        overflow: visible;
    }

    &:hover {
        filter: invert(20%);
    }

    &:active {
        transition: filter .3s;
        filter: invert(15%);
    }   

    @include lt-md {
        opacity: 0;
        top: 15px;
        right: 80px;

        .FwNavbar.active & {
            opacity: 1;
        }
    }
}