@import "/src/mediaQueries.scss";

.FwLandingPage {
    scroll-snap-type: y mandatory;
}

.content {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;

    &:first-of-type {
        padding-top: 80px;
    }

    @include lt-md {
        flex-direction: column;

        &:first-of-type {
            padding-top: 60px;
        }
    }

    @include lt-sm {
        &:first-of-type {
            padding-top: 40px;
        }
        min-height: 90vh;
    }
}

.FwLandingPage .content {
    scroll-snap-align: start;
}