@import "/src/mediaQueries.scss";

.FwContactPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include lt-md {
        flex-direction: column;
        margin-top: 45px;
    }
}

.FwContactPage__faq {
    background-color: var(--col-content-bg);
    color: var(--col-text);
    width: 50%;
    margin: 50px;

    @include lt-md {
        width: 85%;
        margin: 0;
        margin-top: 50px;
    }
}

.FwContactPage__faqList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.FwContactPage__faqListItem {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    border: .1px solid rgba(0, 0, 0, 0.158);
    position: relative;
    transition: background-color .3s ease;
    box-sizing: border-box;

    svg {
        position: absolute;
        right: 30px;
        transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        pointer-events: none;
    }

    &:hover {
        background-color:rgba(0, 0, 0, 0.041);
    }

    &:hover > svg {
        fill: var(--col-secondary);
    }

    &.active {
        svg {
            transform: rotate(90deg);
        }

        & + div {
            height: 200px;

            & .text {
                opacity: 1;
            }

            @include lt-md {
                height: 300px;
            }
        }
    }
}


.FwContactPage__faqListItemTitle {
    margin-left: 50px;
    pointer-events: none;
}

.FwContactPage__faqListItemContent {
    height: 0;
    width: 100%;
    background-color: var(--col-content-bg);
    overflow: hidden;
    transition: height .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    .text {
        opacity: 0;
        transition: opacity .3s ease-in-out;
        padding: 10px 50px 0 50px;
    }
}

.FwContactPage__links {
    background-color: var(--col-content-bg);
    width: 20%;

    @include lt-md {
        width: 85%;
    }
}

.FwContactPage__linksList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.FwContactPage__linksListItem a {
    width: 100%;
    height: 50px;
    border: .1px solid rgba(0, 0, 0, 0.158);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color .3s ease, color .3s ease;
    color: var(--col-text);

    h4 {
        margin-left: 50px;
        display: flex;
        align-items: center;
        transition: margin-left .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &:hover {
        background-color:rgba(0, 0, 0, 0.041);
        color: var(--col-secondary);
        text-decoration: none;

        h4:not(svg) {
            margin-left: 20px;
        }
    }

    &:active {
        background-color:rgba(255, 41, 201, 0.041);
    }

    @include lt-md {
        height: 60px;
    }
}

