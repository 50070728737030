@import "/src/mediaQueries.scss";

.landingBubble {
    position: relative;
    animation: float 10s ease-in-out infinite alternate;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 650px;
    height: 550px;

    &:before {
        content: "";
        background: var(--col-content-bg);
        -webkit-mask-image: url("../../assets/svg/bubbleLanding.svg");
        mask-image: url("../../assets/svg/bubbleLanding.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        width: 650px;
        height: 550px;
    }

    @include lt-lg {
        width: 550px;
        height: 400px;
    }

    @include lt-md {
        img {
            width: 425px;
        }
    }

    @include lt-sm {
        margin-top: 13vh;
        margin-left: 40px;
        align-self: flex-start;
        line-height: 35px;
        width: 75%;
        height: calc(85vw - 100px);

        &:before {
            width: 350px;
            height: 450px;
        }
    }
}

.landingBubble__text {
    z-index: 10;
    cursor: default !important;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    padding-left: 200px;

    h1 {
        font-size: 45px;
        color: var(--col-text);
    }

    .landingBubble__text_letters {
        font-size: 60px;
        text-transform: uppercase;
        letter-spacing: 7px;
    }

    span {
        transition: background-color .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-color: var(--col-secondary);
        transform: rotate(0deg);

        &:hover {
            background-color: var(--col-primary);
            transition: background-color .1s ease-in-out;
        }
    }

    @include lt-lg {
        h1 {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 45px;
        }
        span {
            font-size: 45px;
        }
     }

     @include lt-md {
        padding-left: 150px;
    }

    @include lt-sm {
        padding-left: 30%;
        h1 {
            font-size: 22px;
            line-height: 22px;
            margin-bottom: 25px;
            letter-spacing: .6px;
        }
        span {
            font-size: 33px;
            margin-left: -3px;
        }

        .FwButton {
            align-self: center;
        }
    }
}
