@import "/src/mediaQueries.scss";

.FwSkills {
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    position:relative;

    @include lt-lg {
        flex-direction: column;
    }
}

.FwSkills__title {
    width: 500px;
    height: 270px;
    background: var(--col-content-bg);
    -webkit-mask-image: url("../../assets/svg/bubbleSkillsTitle.svg");
    mask-image: url("../../assets/svg/bubbleSkillsTitle.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    font-size: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    animation: float 7s ease-in-out infinite;

    h1 {
        text-align: left;
        color: var(--col-text)
    }

    span {
        color: var(--col-secondary);
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 40px;
    }

    @include lt-md {
        width: 600px;
        margin-top: 150px;
        margin-bottom: 50px;
    }

    @include lt-sm {
        width: 85vw;
        margin-top: 0;
        margin-bottom: 20px;
    }
}



.FwSkills__skills {
    width: 700px;
    height: 570px;
    background: var(--col-content-bg);
    -webkit-mask-image: url("../../assets/svg/bubbleSkills.svg");
    mask-image: url("../../assets/svg/bubbleSkills.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        width: 400px;
        height: 400px;
    }

    body.color-scheme--dark & canvas {
        filter: invert(1);
    }

    @include lt-lg {
        width: 900px;
        height: 400px;

        div {
            position: absolute;
            width: 40%;
            height: 40%;
        }
    }

    @include lt-md {
        width: 900px;
        height: 350px;

        div {
            position: absolute;
            width: 30%;
            height: 30%;
        }
    }

    @include lt-sm {
        width: 85vw;
        margin-bottom: 50px;
        div {
            position: absolute;
            width: 60%;
            height: 60%;
        }
    }
}