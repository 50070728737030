.FwRandomLettersPrivacy__container{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FwRandomLettersPrivacy__content {
    width: min(500px, 95vw);
    overflow: hidden;
    background-color: var(--col-content-bg);
    color: var(--col-text);
    text-align: justify;
    padding: 20px 25px 20px 25px;
}