.FwEasterEgg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--col-bg);
    color: white;
}

.FwEasterEgg__board {
    width: 300px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    border: 4px solid var(--col-secondary);
}

.FwEasterEgg__board__button {
    width: 100px;
    height: 100px;
    background-color: var(--col-bg);
    color: var(--col-secondary);
    border: 2px solid var(--col-secondary);
    transition: color .3s ease-in-out, background-color .2s ease-in-out;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    &:hover {
        background-color: var(--col-secondary);
        color: white;
    }

    &:active {
        background-color: var(--col-primary);
        color: var(--col-secondary);
    }

    &:not(:empty):hover, &:not(:empty):active {
        background-color: var(--col-bg);
        color: var(--col-secondary);
    }
}
