@import "/src/mediaQueries.scss";

.FwProjectItem {
    position: relative;
    width: 400px;
    height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--col-content-bg);
    color: var(--col-text);
    padding: 50px;
    margin-bottom: 70px;
    transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.active {
        height: 900px;
        
        img {
            height: 400px;
            opacity: 1;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        svg {
            transform: rotate(90deg);
        }

        @include lt-md {
            img {
                height: 300px;
            }
        }
    }

    
    &:hover {
        @include gt-md {
            transform: scale(105%);
        }
        
        svg {
            color: var(--col-secondary);
        }
    }
    

    svg {
        pointer-events: none;
        position: absolute;
        bottom: 30px;
        right: 35px;
        transition: transform .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    @include lt-md {
        width: 300px;
        height: auto;
    }

    @include lt-sm {
        width: 100%;
        margin: 0;
        border-bottom: 3px solid var(--col-bg);
    }
}

.FwProjectItem__text {
    pointer-events: none;
    text-align: justify;
    width: 100%;

    h1 {
        color: var(--col-secondary);
        text-align: left;
    }

    hr {
        color: var(--col-primary);
    }
}


.FwProjectItem__image {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    img {
        width: 400px;
        height: 0;
        opacity: 0;
        transition: height .2s cubic-bezier(0.075, 0.82, 0.165, 1), opacity .1s linear;
    }

    @include lt-md {
        img {
            width: 300px;
        }
    }
 }

.FwProjectItem__links {
    pointer-events: all;

    a {
        text-decoration: none !important;
        &::after {
            content: "•";
            color: white;
            margin: 4px;
        }
    
        &:last-of-type::after {
            content: "";
        }
    }
}
