@import "/src/mediaQueries.scss";

.profileBubble {
    position: relative;
    animation: float 6s ease-in-out infinite alternate;
    display: flex;
    flex-direction: column;
    background: var(--col-content-bg);
    -webkit-mask-image: url("../../assets/svg/bubbleProfile.svg");
    mask-image: url("../../assets/svg/bubbleProfile.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 450px;
    height: 450px;

    img {
        width: 450px;
        z-index: -1;
        filter: saturate(50%);
        transition: box-shadow .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    @include lt-lg {
        width: 350px;
    }

    @include lt-md {
        width: 300px;
    }

    @include lt-sm {
        width: 250px;
        margin-top: -60px;
        margin-right: 20px;
        align-self: flex-end;
    }
}

.profileBubble__img {
    position: absolute;
    z-index: 10;
    cursor: default !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    img {
        width: 250px;
        border-radius: 50%;
        margin: -30px 0 0 80px;
    }

    @include lt-lg {
        img {
            width: 210px;
            margin: -10px 0 0 50px;
        }
    }

    @include lt-md {
        img {
            width: 175px;
        }
    }

    @include lt-sm {
        img {
            width: 140px;
        }
    }
}


