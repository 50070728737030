@import "/src/mediaQueries.scss";

:root {
  --col-primary: #2751a7;
    --col-lowAlpha: #ffffffBB;
    --col-secondary: #D93636;
    --col-text: #000;
    --col-content-bg: #ffffff;
    --col-bg: #223b6d;
    --col-link: #2E6FF2;
    --col-link-hover: #2E6FF2;
    --col-link-active: #D98989;

  .color-scheme--light {
    --col-primary: #2751a7;
    --col-lowAlpha: #ffffffBB;
    --col-secondary: #D93636;
    --col-text: #000;
    --col-content-bg: #ffffff;
    --col-bg: #223b6d;
    --col-link: #2E6FF2;
    --col-link-hover: #2E6FF2;
    --col-link-active: #D98989;
  }

  .color-scheme--dark {
    --col-primary: #2751a7;
    --col-lowAlpha: #292929BB;
    --col-secondary: #da3d3d;
    --col-text: #fff;
    --col-content-bg: #292929;
    --col-bg: #223b6d;
    --col-link: #6192f3;
    --col-link-hover: #2E6FF2;
    --col-link-active: #D98989;
  }

  .color-scheme--dark--old {
    --col-primary: #3e3ba0;
    --col-lowAlpha: #292929BB;
    --col-secondary: #F24968;
    --col-text: #fff;
    --col-content-bg: #292929;
    --col-bg: #1d1d1d;
    --col-link: #7f7cd8;
    --col-link-hover: #af334a;
    --col-link-active: #03A6A6;
  }

  max-width: 100vw;

  a:link {
    color: var(--col-link);
  }
  a:visited {
    color: var(--col-link);
  }
  a:hover {
    color: var(--col-link-hover);
  }
  a:active {
    color: var(--col-link-active);
  }
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  @include lt-md {
    scroll-snap-type: none;
  }
}

.text {
  text-align: justify;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--col-secondary) var(--col-content-bg);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 0px;
  }

  *::-webkit-scrollbar-track {
    background: var(--col-content-bg);
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--col-secondary);
    border-radius: 0;
    border: 0px none var(--col-content-bg);
  }

.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--col-bg);
  scroll-behavior: smooth;
  overflow-x: clip;
}

.wrapper {
  pointer-events: none;
}

.content > div {
  pointer-events: all;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

a:link, a:visited {
  color: var(--col-primary);
  text-decoration: none;
}

a:link:hover, a:visited:hover {
  color: var(--col-secondary);
  text-decoration: underline;
}