@import "/src/mediaQueries.scss";

.FwAboutMe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--col-content-bg);
    -webkit-mask-image: url("../../assets/svg/bubbleAboutMe.svg");
    mask-image: url("../../assets/svg/bubbleAboutMe.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    
    @include gt-md {
        padding: 280px 180px 280px 180px;
    }

    @include lt-lg {
        padding: 25px;
        -webkit-mask-image: none;
        mask-image: none;
        background-color: var(--col-content-bg);
        color: var(--col-text);
        height: 50vh;
        width: 100%;
    }

    @include lt-md {
        padding: initial;
        background-color: var(--col-content-bg);
        color: var(--col-text);
        width: 100%;
        height: 100vh;
    }

    @include lt-sm {
        margin: 100px 0 100px 0;
    }   
}

.FwAboutMe__text {
    z-index: 1;
    width: 700px;
    text-align: justify;
    color: var(--col-text);

    h1 {
        font-size: 50px;
        text-transform: uppercase;
        letter-spacing: 5px;
        color: var(--col-secondary);
    }

    p {
        margin-bottom: 20px;
    }

    .FwButton {
        float: right;
        top: 60px;
        right: 165px;
    }

    @include lt-lg {
        width: 80%;
        padding: 0px 20px 40px 20px; 
        
        h1 {
            line-height: 30px;
        }

        p {
            padding: 5px;
            margin-bottom: 50px;
        }

        .FwButton {
            top: auto;
            right: auto;
            float: none;
        }
    }

}
